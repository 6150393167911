import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faFlickr} from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
    return ( 
        <>
        <Redes>
			<Contenedor>
 
				<Iconos href="https://www.facebook.com/alfredsis"> <FontAwesomeIcon icon={faFacebook} />  </Iconos>
                <Iconos href="https://www.instagram.com/alfredsis"> <FontAwesomeIcon icon={faInstagram} />  </Iconos>
                <Iconos href="https://www.youtube.com/channel/UCT27p9AaFwJUlkNShh4ta6g"> <FontAwesomeIcon icon={faYoutube} />  </Iconos>
                <Iconos href="https://flickr.com/photos/alfredizaguirre"> <FontAwesomeIcon icon={faFlickr} />  </Iconos>
				
			</Contenedor>
		</Redes>
        </>
     );
}



const Redes=styled.section`
	background: #1e2b38;
	padding: 20px 0;
`;

const Iconos=styled.a`
	color: #fff;
	text-align: center;
	width: 100px;
	display: block;
	padding: 15px 0;
	border-radius: 3px;
	font-size: 30px;
	
    transition: all .2s ease-in-out; 
    &:hover{

        transform: scale(1.3); 
    }
`;

const Contenedor=styled.div`
	display: flex;
	justify-content:center;
    

`;
 
export default Footer;