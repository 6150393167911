import React from 'react';
import Header from './components/Header'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import Precios from './pages/Precios';
import Blog from './pages/Blog';
import AcercaDe from './pages/AcercaDe';
import Footer from './components/footer';
import Maternidad from './components/categorias/Maternidad';
import QuinceA from './components/categorias/QuinceA';
import Retratos from './components/categorias/Retratos';
import Eventos from './components/categorias/Eventos';
import Compromiso from './components/categorias/Compromiso';
import Inmobiliaria from './components/categorias/Inmobiliaria';
import Bebes from './components/categorias/Bebes';
import Graduacion from './components/categorias/Graduacion';





const App =() => {
  return (
    <BrowserRouter>
      
      <Header/>

      <Routes>
        
            
      <Route path="/" element={ <Home /> } />
      <Route path='/precios' element ={ <Precios/> }/>
      <Route path='/blog' element ={ <Blog/> }/>
      <Route path='/Acercade' element ={ <AcercaDe/> }/>
      <Route path='/maternidad' element = { <Maternidad />}/>
      <Route path='/quince' element = {<QuinceA/> }/>
      <Route path='/retratos' element = {<Retratos/> }/>
      <Route path='/compromiso' element = {<Compromiso />}/>
      <Route path='/eventos' element = {<Eventos />}/>
      <Route path='/inmobiliaria' element = {<Inmobiliaria />}/>
      <Route path='/bebes' element = {<Bebes />}/>
      <Route path='/graduacion' element = {<Graduacion />}/>


       </Routes>   

      <Footer/>
   </BrowserRouter>
  );
}

export default App;
