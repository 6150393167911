
 import image1 from '../../imag/compromiso/1.jpg'; 
 import image2 from '../../imag/compromiso/2.jpg';
 import image3 from '../../imag/compromiso/3.jpg';
 import image4 from '../../imag/compromiso/4.jpg';
 import image5 from '../../imag/compromiso/5.jpg';
 import image6 from '../../imag/compromiso/6.jpg';
 import image7 from '../../imag/compromiso/7.jpg';
 import image8 from '../../imag/compromiso/8.jpg';
 import image9 from '../../imag/compromiso/9.jpg';



 const compromisoImg = [
    image1, image2, image3, image4, 
    image5, image6, image7, image8,
    image9
];
export default compromisoImg;