import React from "react";
import styled from "styled-components";




const Blog = () => {
    return ( 
        <>
        <Main>
         <Contenedor>
                <Space> </Space>  
                <Titulo>Cinco locaciones para sesiones de fotos cerca de Ciudad de Guatemala</Titulo>
                <Space> </Space>
                <Subtitulo>Parque ecologico jacarandas de cayalá</Subtitulo>
                <Parrafo>Ubicado en zona 5 de la ciudad capital, posee diferentes puntos para hacer fotos, 
                    como puentes, senderos, flores, etc. El precio de la entrada es de Q15, parqueo Q10. 
                    El horario es de martes a domingo de 8:00 a 17:00</Parrafo>
                <Imagen src="https://www.guatemala.com/fotos/201707/Jacarandas2-885x500.jpg" alt="parque jacarandas de cayala" />
                <Imagen src="https://live.staticflickr.com/2754/4038131712_e2740fd4ca_b.jpg" alt="foto por Luis Fernando Muñoz Chacon "/>
                <Space> </Space> 

                <Subtitulo>4 Grados norte</Subtitulo>
                <Space> </Space>
                <Parrafo> Ubicado en la zona 4 de la ciudad capital, es un espacio publico abierto con diferentes tipos de decoración, desde jardines,
                     calles hasta murales. Ideal para cualquier sesión de fotos urbana o con un ambiente más informal.</Parrafo>
                <Imagen src="https://pbs.twimg.com/media/FNBttJ8XMAUFPft.jpg" alt="4 grados norte guatemala" />
                <Imagen src="https://www.guatemala.com/fotos/201708/4-grados-norte-885x500.jpg" alt="4 grados norte" />
                <Space> </Space>

                <Subtitulo>Ciudad cayalá</Subtitulo>
                <Space> </Space>
                <Parrafo>Un centro comercial con increíble arquitectura, muchas personas piensan que no es posible hacer fotos allí, 
                    pero si solicitas el permiso con tiempo y pagas el costo de unos Q500 aproximadamente, 
                    se puede hacer una sesión de fotos sin problemas.</Parrafo>
                <Imagen src="https://planosyestilos.com/wp-content/uploads/2021/12/ciudad-cayala.jpg" alt="Ciudad cayala" />  
                <Imagen src="https://revistasumma.com/wp-content/uploads/2021/09/cayala-guatemala.jpg" alt="ciudad cayala" />  
                <Space> </Space>

                <Subtitulo>Cerro alux</Subtitulo>
                <Space> </Space>
                <Parrafo>Un parque ubicado en las afueras de la ciudad, llegando a san lucas Sacatepéquez, es un espacio abierto con naturaleza con diferentes puntos para obtener fotos familiares, 
                    parejas y de cualquier tipo. El precio es de Q30 y el horario de martes a domingos de 8am a 4pm</Parrafo>

                <Imagen src="https://agn.gt/wp-content/uploads/2020/12/senderos-alux.jpg" alt="cerro alux" />  
                <Imagen src="https://www.guatemala.com/fotos/2020/10/Fecha-de-reactivacion-del-parque-ecologico-Senderos-de-Alux-Octubre-2020-1-885x500.jpg" alt="cerro alux" />  
                <Space> </Space>

                <Subtitulo>Plazuela España</Subtitulo>
                <Space> </Space>
                <Parrafo>En la zona 9 de la ciudad es un espacio publico abierto donde es muy común hacer sesión de fotos. 
                    El lugar no tiene un parqueo en si pero hay uno justo enfrente, atrás de office depot de Q10 la hora. 
                    En cuanto a seguridad, se ven policías de transito y nacional civil transitar comúnmente, 
                    pero como seguridad adicional se puede solicitar escolta con la policía de turismo por algún monto mínimo.</Parrafo>
                <Imagen src="https://upload.wikimedia.org/wikipedia/commons/2/27/Plaza_Espa%C3%B1a%2C_Ciudad_de_Guatemala.jpg" alt="plaza españa guatemala" />
                <Imagen src="https://movilidadynoticias.com.gt/wp-content/uploads/2021/05/plaza-espana.jpg" alt="plazuela españa" />
                <Space> </Space>






         </Contenedor>
         </Main>

        
        </>


     );
}

const Main=styled.div`
display: flex;
justify-content: center;
padding: 0 20px;
`;

const Contenedor=styled.div`

width: 700px;

@media screen and (max-width: 800px) {
      width: 90%;
    }





`;

const Space=styled.div`
height: 50px;
`;

const Titulo=styled.h1`
font-family: 'Lato', sans-serif;
font-size: 35px;

`;

const Subtitulo=styled.h2`
font-family: 'Lato', sans-serif;
font-size: 25px;
`;

const Parrafo=styled.p`
font-family: 'Poppins', sans-serif;
justify-content: center;
font-size: 17px;
text-align: justify;
`;

const Imagen=styled.img`
width: 700px;

@media screen and (max-width: 800px) {
  width: 90%;
    }
  

`;


 
export default Blog;