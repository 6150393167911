import React from "react";
import logo from '../imag/logo.png'
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
 



const Header = () => {
    return ( 
    <>    
    <header>
        <Logo className="logo">
            <a href="/"><Imagenlogo src={logo} alt="logo alfred fotografo"/></a>
        </Logo>    
        
        <Locacion className="locacion">CIUDAD DE GUATEMALA</Locacion>
        <Menu>
            
            <NavLink to="/">home</NavLink>
            <NavLink to="/precios">precios</NavLink>
            <NavLink to="/blog">blog</NavLink>
            <NavLink to="/acercade">acerca de</NavLink>
                  
                       
        </Menu>
    </header>
    </>
     );
}
 




 
const Logo = styled.div`
    text-align: center;
    padding: 0;
`;

const Imagenlogo = styled.img`
   width: 200px;
   margin-bottom: -10px;
   min-width: 10%;

   @media screen and (max-width: 800px) {
    width: 120px;
   }
   
`;

const Locacion = styled.p`
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 40px;
    margin-top: 0;
    color: rgb(68, 68, 68);
    letter-spacing: 5px;
    @media screen and (max-width: 800px) { 
        font-size: 0.7rem;
    }
    
`;

const Menu = styled.nav`
    text-align: center;
    justify-content: center;
    margin-bottom: 30px; 
    
    a{
    text-decoration: none;
    color: black;
    font-family: 'Noto Sans', sans-serif;
    padding: 0 20px;

    @media screen and (max-width: 400px) {
        text-align: center;
        justify-content: center;
        padding: 0 8px;
        
    }

    }
    a:hover {
    color: rgb(36, 36, 36);
    font-weight: 600; 
    
    }
    a:active{
    font-weight: 600;  
    }

 

    


`;



export default Header;