
 import image1 from '../../imag/inmobiliaria/1.jpg'; 
 import image2 from '../../imag/inmobiliaria/2.jpg';
 import image3 from '../../imag/inmobiliaria/3.jpg';
 import image4 from '../../imag/inmobiliaria/4.jpg';
 import image5 from '../../imag/inmobiliaria/5.jpg';
 import image6 from '../../imag/inmobiliaria/6.jpg';
 import image7 from '../../imag/inmobiliaria/7.jpg';
 import image8 from '../../imag/inmobiliaria/8.jpg';




 const inmobiliariaImg = [
    image1, image2, image3, image4, 
    image5, image6, image7, image8
];
export default inmobiliariaImg;