
 import image1 from '../../imag/graduacion/1.jpg'; 
 import image2 from '../../imag/graduacion/2.jpg';
 import image3 from '../../imag/graduacion/3.jpg';
 import image4 from '../../imag/graduacion/4.jpg';
 import image5 from '../../imag/graduacion/5.jpg';
 import image6 from '../../imag/graduacion/6.jpg';



 const graduacionImg = [
    image1, image2, image3, image4, 
    image5, image6
];
export default graduacionImg;