import cat1 from '../../imag/cat01.jpg'
import cat2 from '../../imag/cat02.jpg'
import cat3 from '../../imag/cat03.jpg'
import cat4 from '../../imag/cat04.jpg'
import cat5 from '../../imag/cat05.jpg'
import cat6 from '../../imag/cat06.jpg'
import cat7 from '../../imag/cat07.jpg'
import cat8 from '../../imag/cat08.jpg'

export const CategoriasImg =  {
   cat1, cat2, cat3, cat4, cat5, cat6, cat7, cat8 

}

