
 import image1 from '../../imag/maternidad/1.jpg'; 
 import image2 from '../../imag/maternidad/2.jpg';
 import image3 from '../../imag/maternidad/3.jpg';
 import image4 from '../../imag/maternidad/4.jpg';
 import image5 from '../../imag/maternidad/5.jpg';
 import image6 from '../../imag/maternidad/6.jpg';
 import image7 from '../../imag/maternidad/7.jpg';
 import image8 from '../../imag/maternidad/8.jpg';
 import image9 from '../../imag/maternidad/9.jpg';
 import image10 from '../../imag/maternidad/10.jpg';
 import image11 from '../../imag/maternidad/11.jpg';
 import image12 from '../../imag/maternidad/12.jpg';
 import image13 from '../../imag/maternidad/13.jpg';


 const maternidadImg = [
    image1, image2, image3, image4, 
    image5, image6, image7, image8,
    image9, image10, image11, image12, image13
];
export default maternidadImg;


    // a: require('../../imag/maternidad/1.jpg').default,
    // b: require('../../imag/maternidad/2.jpg').default,
    // c: require('../../imag/maternidad/3.jpg').default,
    // d: require('../../imag/maternidad/4.jpg').default,
    // e: require('../../imag/maternidad/5.jpg').default,
    // f: require('../../imag/maternidad/6.jpg').default,
    // g: require('../../imag/maternidad/7.jpg').default,
    // h: require('../../imag/maternidad/8.jpg').default,
    // i: require('../../imag/maternidad/9.jpg').default,
    // j: require('../../imag/maternidad/10.jpg').default,
    // k: require('../../imag/maternidad/11.jpg').default,
    // l: require('../../imag/maternidad/12.jpg').default,
    // m: require('../../imag/maternidad/13.jpg').default





// for (i=1, i<=13, i++ ){

//     require(`../../imag/maternidad/${i}.jpg`).default

// }