import React from "react";
import styled from "styled-components";
import { CategoriasImg } from "../components/categorias/CategoriasImg";
import { NavLink } from "react-router-dom";


const Home = () => {
    return (    

<Main>
    <Contenedor>



        <Categoria>        
            <Thumb>
                <Img src={CategoriasImg.cat1} alt="categoria forografia"/>
            </Thumb>
            <Descripcion>
                
                <NavLink to="/maternidad">maternidad</NavLink>
            </Descripcion>
        </Categoria>

        <Categoria>        
            <Thumb>
                <Img src={CategoriasImg.cat2} alt="categoria forografia"/>
            </Thumb>
            <Descripcion>
                
                <NavLink to="/quince">15 años</NavLink>
            </Descripcion>
        </Categoria>

        <Categoria>        
            <Thumb>
                <Img src={CategoriasImg.cat3} alt="categoria forografia"/>
            </Thumb>
            <Descripcion>
                
                <NavLink to="/retratos">retratos</NavLink>
            </Descripcion>
        </Categoria>

        <Categoria>        
            <Thumb>
                <Img src={CategoriasImg.cat4} alt="categoria forografia"/>
            </Thumb>
            <Descripcion>
                
                <NavLink to="/eventos">eventos</NavLink>
            </Descripcion>
        </Categoria>

        <Categoria>        
            <Thumb>
                <Img src={CategoriasImg.cat5} alt="categoria forografia"/>
            </Thumb>
            <Descripcion>
                
                <NavLink to="/compromiso">compromisos</NavLink>
            </Descripcion>
        </Categoria>

        <Categoria>        
            <Thumb>
                <Img src={CategoriasImg.cat6} alt="categoria forografia"/>
            </Thumb>
            <Descripcion>
                
                <NavLink to="/inmobiliaria">inmobiliaria</NavLink>
                
            </Descripcion>
        </Categoria>

        <Categoria>        
            <Thumb>
                <Img src={CategoriasImg.cat7} alt="categoria forografia"/>
            </Thumb>
            <Descripcion>
               
                <NavLink to="/bebes">new Born</NavLink>
            </Descripcion>
        </Categoria>

        <Categoria>        
            <Thumb>
                <Img src={CategoriasImg.cat8} alt="categoria forografia"/>
            </Thumb>
            <Descripcion>
                
                <NavLink to="/graduacion">graduación</NavLink>
            </Descripcion>
        </Categoria>

       
    </Contenedor>

</Main>


      );
}

const Main = styled.div`
  background-color: rgb(249, 249, 249);

`;
const Contenedor = styled.section`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: 800px) {
      display: grid;
      grid-template-columns: auto;
      justify-content: center;
      text-align: center;
    }

`;

const Categoria = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
`;

const Thumb = styled.div`
    margin-bottom: -4px;
`;

const Descripcion =styled.div`
    border: 2px solid #fff;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   padding: 10px 25px;
    cursor: pointer;

    a{
        text-decoration: none;
        font-size: 25px;
        color: #fff;
        letter-spacing: 3px;

    }

`;

const Img = styled.img`
    width: 100%;
    height: auto;
    filter: brightness(0.7);
    &:hover { 
        filter: brightness(0.9);
    transition: all 0.3s ease;
    }

    @media screen and (max-width: 1200px) {

    max-height: auto;
    max-width: 100%;
    }
     
`;



export default Home;