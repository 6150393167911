
 import image1 from '../../imag/15/1.jpg'; 
 import image2 from '../../imag/15/2.jpg';
 import image3 from '../../imag/15/3.jpg';
 import image4 from '../../imag/15/4.jpg';
 import image5 from '../../imag/15/5.jpg';



 const quinceImg = [
    image1, image2, image3, image4, 
    image5
];
export default quinceImg;