import React, { useState } from "react";
import styled from "styled-components";
import inmobiliariaImg from '../photos/inmobiliaria'
// import Visual from "./visualizador";    posible componente para el visualizador
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import '../style.css'

const Retratos = () => {


    const [visualizar, setVisualizar] = useState(false);
    const [imagen, setimagen] = useState('');

const mirar = (index, img)=>{ 

 
//  document.body.style.overflowY = 'hidden';
  
setVisualizar(true);
setimagen(img);
// {window.addEventListener('click', (e) => {if (e.target !== e.currentTarget) return setVisualizar(false)}) }  Para clickear fuera del elemtento seleccionado

};


    return ( 

        <>
        <Titulo>Inmobiliaria</Titulo>
        <MainImagen>
            <Imagenes>   {/* METER LAS IMAGENES EN UN ARREGLO */}
               
                    {/*llama a todas las imagenes del arreglo*/}
                {inmobiliariaImg.map((img, index)=> (
                    <Img key={index} src={img} alt={img} onClick={()=>{ mirar(index, img); document.body.style.overflowY = 'hidden'; }} />                      
                ) )}                                                       
              
            </Imagenes>
            
        </MainImagen>

            {visualizar ? 
              <div className={visualizar ? "lightbox" : ""} > 
                    <button onClick={ ( )=>{ setVisualizar(false); document.body.style.overflowY = 'auto';}}  >    {/*meter todo dentro de una funcion y mandarle los parametros*/}
                    <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <img  src={imagen} alt="sesion de fotos"/>
              </div> : ""

            }      

                    

    </>

 );
 

     
}
 

const Titulo=styled.h2`
  text-align: center;
  padding: 50px;
  font-size: 40px;
  font-weight: 300;
  letter-spacing: 5px;
  font-family: 'Noto Sans', sans-serif;
`;

const MainImagen=styled.div`
  display: flex;
  justify-content: center;
 padding: 40px 0;
 
`;

const Imagenes =styled.div`
    width: 70%;
    /* display: grid;
    grid-template-columns: auto auto; */
   display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px 25px;
`;

const Img = styled.img`
  max-height: 500px;
  height: auto;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    
    max-height: auto;
    max-width: 100%;

    
  }


`;

export default Retratos;