import React from "react";
import styled from "styled-components";


const Precios = () => {
    return ( 

        <>
            <Contenedor>
                <PreciosCont>
                    <Titulo>Sesiones</Titulo>
                    <Precio>Q500</Precio>
                    <Parrafo>18 fotos digitales</Parrafo>
                    <Parrafo>1 hora de sesion</Parrafo>
                    <Parrafo>Ciudad de Guatemala o Antigua</Parrafo>
                    <Parrafo>Hasta 2 cambios de ropa</Parrafo>
                </PreciosCont>

                <PreciosCont>
                    <Titulo>Eventos</Titulo>
                    <Precio>Q750</Precio>
                    <Parrafo>60 fotos digitales</Parrafo>
                    <Parrafo>2 horas de sesion</Parrafo>
                    <Parrafo>Ciudad de Guatemala o Antigua</Parrafo>
                </PreciosCont>
            </Contenedor>
        </>

     );
}

const Contenedor =styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0 50px;
  padding: 50px 0;
  min-height: 60vh;
 
  /* height: 40rem; */
  



  @media screen and (max-width: 800px) {

  gap: 50px;
  

}

`;

const PreciosCont =styled.div`
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px 30px;
  border-radius: 15px;
  height: 28rem;
  


  
`;


const Parrafo =styled.p`
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    padding: 15px 0;
    color: #232323;
`;

const Titulo=styled.h2`
  font-family: 'Lato', sans-serif;
  font-size: 30px;
  padding: 15px 0;

`;

const Precio = styled.p`

  font-family: 'Lato', sans-serif;
  font-size: 60px;
  padding: 15px;
  font-weight: 700;
  background-image: -webkit-linear-gradient(120deg,#b142ec 0%,#812bea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;


  
`;




 
export default Precios;