
 import image1 from '../../imag/retratos/1.jpg'; 
 import image2 from '../../imag/retratos/2.jpg';
 import image3 from '../../imag/retratos/3.jpg';
 import image4 from '../../imag/retratos/4.jpg';
 import image5 from '../../imag/retratos/5.jpg';
 import image6 from '../../imag/retratos/6.jpg';
 import image7 from '../../imag/retratos/7.jpg';
 import image8 from '../../imag/retratos/8.jpg';
 import image9 from '../../imag/retratos/9.jpg';



 const retratosImg = [
    image1, image2, image3, image4, 
    image5, image6, image7, image8,
    image9
];
export default retratosImg;