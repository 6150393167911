import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import  './style.css'


const FormularioContacto = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_92ipdlb', 'template_i5facsp', form.current, '8scgBsPfZETgaXR9s')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
          alert('Mensaje enviado exitosamente.');
      }, (error) => {
          console.log(error.text);
      });
  };


    return ( 
        <div className="divPrimario">
          
          <div className="divSecundario" >
            <form ref={form} onSubmit={sendEmail} >
              
              <p className="pForm" >Para cotizaciones o cualquier consulta.</p>
    
              <div className="divForm">
                  <input className="campos" name="user_name" type="text"  id="name" placeholder="Nombre" required/>
              </div>
              <div className="divForm" >
                  <input type="email"  placeholder="Email" name="user_email" required/>
              </div>
              <div className="divForm">
                  <textarea id="message"  placeholder="Mensaje" name="message" required></textarea>
                  {/* <div class="g-recaptcha" data-sitekey="6Lc2b9cgAAAAAObFsrxwRMeU5nJOCwEfW1ZNvquO" ></div> */}
              </div>
              
              <div className="divForm">
                <input className="boton" type="submit" name="button" value="Enviar"/>
              </div>
            </form>
          </div>

          
            
        </div>
     );
}
 
export default FormularioContacto;