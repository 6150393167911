import React from "react";
import styled from "styled-components";
import about from '../imag/about.png'
import FormularioContacto from "../components/FormularioContacto";
import youtube from '../imag/iconos/youtube.png'
import instagram from '../imag/iconos/instagram.png'
import facebook from '../imag/iconos/facebook.png'
import flickr  from '../imag/iconos/flickr.png'

const AcercaDe = () => {
    return ( 
      <>
        <Contenedor>
        
        <Imagen src={about} alt="alfredo sis" />
        <Nombre>Alfredo Sis Izaguirre</Nombre>
        <Desc>Fotógrafo guatemalteco de Ciudad de Guatemala</Desc>
       </Contenedor>

       <Contenedor>

        <Nombre>Redes Sociales</Nombre>
            <section>
            <div>               
                    <a href="https://instagram.com/alfredsis"> <img src={instagram} alt="instagram icon"/></a>
                    <p>Instagram</p>              

            </div>

            <div>               
                    <a href="https://www.facebook.com/alfredsis"> <img src={facebook} alt="facebook icon"/></a>
                    <p>Facebook</p>              

            </div>

            <div>               
                    <a href="https://www.youtube.com/channel/UCT27p9AaFwJUlkNShh4ta6g"> <img src={youtube} alt="youtube icon"/></a>
                    <p>Youtube</p>              

            </div>

            <div>               
                    <a href="https://flickr.com/photos/alfredizaguirre/"> <img src={flickr} alt="flickr icon"/></a>
                    <p>Flickr</p>              

            </div>
            </section>
         </Contenedor>


       <Contenedor>
       <Nombre>Contacto</Nombre>
          <FormularioContacto/>        
       </Contenedor>
    </>
     );
}


const Contenedor=styled.div`
text-align: center;
margin: 100px 0;

  section{
    padding: 30px 0;
    display: flex;
    justify-content: center;
    gap: 5%;
    
  }
  section div{
    transition: all .2s ease-in-out; 
    &:hover{transform: scale(1.2)}
  }
  section div p{   
    
      font-family: 'Playfair Display', serif;
      font-size: 18px;

  }
  section div img{
    width: 70px;
  }

`;
 
const Imagen=styled.img`
width: 150px;
border: 5px solid rgb(181, 181, 181);
border-radius: 50%;

`;

const Nombre=styled.h2`
font-family: 'Playfair Display', serif;
font-size: 35px;
font-weight: 500;
padding: 20px 0;
`;

const Desc=styled.p`
font-family: 'Playfair Display', serif;
font-size: 20px;
`;


export default AcercaDe;