
 import image1 from '../../imag/bebes/1.jpg'; 
 import image2 from '../../imag/bebes/2.jpg';
 import image3 from '../../imag/bebes/3.jpg';
 import image4 from '../../imag/bebes/4.jpg';
 import image5 from '../../imag/bebes/5.jpg';
 import image6 from '../../imag/bebes/6.jpg';



 const bebesImg = [
    image1, image2, image3, image4, 
    image5, image6
];
export default bebesImg;