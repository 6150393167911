
 import image1 from '../../imag/eventos/1.jpg'; 
 import image2 from '../../imag/eventos/2.jpg';
 import image3 from '../../imag/eventos/3.jpg';
 import image4 from '../../imag/eventos/4.jpg';
 import image5 from '../../imag/eventos/5.jpg';
 import image6 from '../../imag/eventos/6.jpg';
 import image7 from '../../imag/eventos/7.jpg';
 import image8 from '../../imag/eventos/8.jpg';
 import image9 from '../../imag/eventos/9.jpg';
 import image10 from '../../imag/eventos/10.jpg';
 import image11 from '../../imag/eventos/11.jpg';



 const eventosImg = [
    image1, image2, image3, image4, 
    image5, image6, image7, image8,
    image9, image10, image11
];
export default eventosImg;